import { Routes, Route } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, FC } from "react";
import { useCall, Loader } from "@epcnetwork/core-ui-kit";

import { isActiveRoute } from "utils";
import { RootState, setUser } from "store";
import { LOGIN_PAGE, DEFAULT_PAGE } from "constants/routes.constants";
import { routes } from "config/routes.config";
import { PageRoute } from "components";
import { getMyProfile } from "api";

const App: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { token, user } = useSelector((state: RootState) => state.auth);

  const [isLoading, setIsLoading] = useState(true);

  const { submit, onCallSuccess, onCallError } = useCall(getMyProfile);

  useEffect(() => {
    if (token && !user) {
      setIsLoading(true);
      submit();
    } else {
      const isLoginPage = isActiveRoute(LOGIN_PAGE.path, location);
      if (isLoginPage) navigate(DEFAULT_PAGE.path);

      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user]);

  onCallSuccess(async (payload) => {
    dispatch(setUser(payload));
    setIsLoading(false);
  });

  onCallError(() => {
    setIsLoading(false);
  });

  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          inset: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader type="puff-loader" size={100} />
      </div>
    );
  }

  return (
    <Routes>
      {routes.map(({ element, ...route }) => (
        <Route
          key={route.path}
          path={route.path}
          element={<PageRoute element={element} {...route} />}
        />
      ))}
    </Routes>
  );
};

export { App };

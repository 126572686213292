import { memo, useCallback, useMemo, useState } from "react";
import { useFormikContext } from "formik";
import cn from "classnames";
import {
  Button,
  FormField,
  useDidUpdate,
  AutocompleteOption,
  useFetch,
} from "@epcnetwork/core-ui-kit";

import { WebhookModel } from "models";
import { Hint } from "components";
import { getProxies } from "api";
import { ReuseJob } from "../reuse-job/reuse-job";
import { InitialValues } from "../jobs-form.types";
import { AttachFile } from "../attach-file/attach-file";
import { EndpointProps, EndpointType, EndpointWebhook } from "./endpoint.types";
import {
  apiTypeOptions,
  initialEndpointsProperties,
  editBatchBtnText,
  createBatchBtnText,
} from "./endpoint.constants";
import { EndpointAPIDispatcher } from "./endpoint-api-dispatcher";

import styles from "../job-form.module.css";

const getWebhookOptions = (webhooksArr: WebhookModel[]): EndpointWebhook[] =>
  webhooksArr.map(({ id, ip, name }) => ({
    label: `${name}: ${ip}`,
    value: id,
  }));

const Endpoint = memo<EndpointProps>(
  ({
    endpointOrdinal,
    endpoint,
    webhooks,
    tagsAutocomplete,
    isInReconfiguration,
    isJobEditing,
    onEndpointError,
  }) => {
    const { setFieldValue, setErrors, values, isSubmitting } = useFormikContext<InitialValues>();

    const { payload: proxyPayload, loading: proxyLoading } = useFetch(getProxies);

    const [isBtnDisabled, setIsBtnDisabled] = useState(false);

    const proxyId = useMemo(
      () => values.endpoints[`${endpointOrdinal}`]?.proxyId,
      [values.endpoints, endpointOrdinal],
    );

    const proxyOptions: AutocompleteOption[] = useMemo(() => {
      if (!proxyPayload) return [];

      return proxyPayload.map(({ id, protocol, password, address, user, port }) => {
        const label = `${protocol}://${user}:${password}@${address}:${port}`;
        return { label, value: id };
      });
    }, [proxyPayload]);

    useDidUpdate(
      () => {
        const proxyId = values.endpoints[endpointOrdinal].proxyId;

        if (proxyId) {
          const option = proxyOptions.find((option) => option.value === proxyId);

          if (option) {
            setFieldValue(`endpoints[${endpointOrdinal}].proxyValue`, option.label);
          }
        }
      },
      [proxyOptions],
      true,
    );

    const {
      connector: { apiType },
    } = endpoint;

    const webhookIdFieldName = `endpoints[${endpointOrdinal}].webhookId`;

    const handleReuseJobSubmit = (newEndpoint: EndpointType) =>
      setFieldValue(`endpoints[${endpointOrdinal}]`, newEndpoint);

    const handleReuseJobClear = () => {
      clearEndpointData();
    };

    const handleDisableSubmit = useCallback((valid: boolean) => {
      setIsBtnDisabled(valid);
    }, []);

    const clearEndpointData = () => {
      setErrors({});
      setFieldValue(
        `endpoints[${endpointOrdinal}].connector.properties`,
        initialEndpointsProperties[apiType],
      );
    };

    const handleApiTypeChange = (value: any) => {
      clearEndpointData();
    };

    const handleProxyInputChange = (value: string | number) => {
      setFieldValue(`endpoints[${endpointOrdinal}].proxyValue`, value);
      setFieldValue(`endpoints[${endpointOrdinal}].proxyId`, null);
    };

    const handleProxyValueSelect = (value: AutocompleteOption | null) => {
      setFieldValue(`endpoints[${endpointOrdinal}].proxyId`, value?.value || null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const webhookSelectOptions = useMemo(() => getWebhookOptions(webhooks), [webhooks.length]);

    useDidUpdate(() => {
      if (webhookSelectOptions.length === 1) {
        setFieldValue(webhookIdFieldName, webhookSelectOptions[0].value);
      }
    }, [webhookSelectOptions.length, endpointOrdinal]);

    useDidUpdate(() => {
      onEndpointError(isBtnDisabled);
    }, [isBtnDisabled]);

    const submitButtonText = isJobEditing ? editBatchBtnText : createBatchBtnText;

    const webhookExternalIp = useMemo(() => {
      if (webhooks.length - 1 >= endpointOrdinal) {
        return webhooks.find((webhook) => webhook.id === endpoint.webhookId)?.externalIp || null;
      }

      return null;
    }, [endpoint, webhooks, endpointOrdinal]);

    const isSalesforce = useMemo(() => {
      return values.endpoints[endpointOrdinal].connector.apiType === "salesforce";
    }, [endpointOrdinal, values.endpoints]);

    useDidUpdate(() => {
      const isExactTarget = values.endpoints[endpointOrdinal].connector.apiType === "salesforce";
      if (isExactTarget) {
        setFieldValue(`endpoints[${endpointOrdinal}].proxyId`, null);
        setFieldValue(`endpoints[${endpointOrdinal}].proxyValue`, "");
      }
    }, [endpointOrdinal, values.endpoints]);

    return (
      <div className={styles.endpointWrap}>
        <div className={styles.leftPanelWrap}>
          <div
            className={cn(styles.leftPanelContentWrap, {
              [styles.leftPanelContentWrapJob]: isJobEditing,
            })}
          >
            {!isInReconfiguration && (
              <ReuseJob
                oldEndpoint={endpoint}
                onSubmitClick={handleReuseJobSubmit}
                onClearClick={handleReuseJobClear}
              />
            )}

            <FormField
              type="select"
              name="tagId"
              label="Batch tag"
              placeholder="Batch tag"
              asyncOptions={tagsAutocomplete}
              isTextEditable
              disabled={!!endpointOrdinal}
              status={(!!endpointOrdinal && "info") || undefined}
              messageStatus={(!!endpointOrdinal && "info") || undefined}
              error={(!!endpointOrdinal && "You can select tag in the first job") || undefined}
            />
            <FormField
              type="text"
              name={`endpoints[${endpointOrdinal}].name`}
              label="Job name"
              placeholder="Job name"
              required
            />
            <FormField
              name={`endpoints[${endpointOrdinal}].connector.apiType`}
              type="select"
              label="API Type"
              placeholder="Select API Type"
              options={apiTypeOptions}
              onChange={handleApiTypeChange}
              sortOptions={false}
              disabled={isInReconfiguration}
              disableArrow={isInReconfiguration}
              disableClearing={isInReconfiguration}
              disableSingleOptionUncheck={isInReconfiguration}
              required
            />
            <div>
              <FormField
                name={webhookIdFieldName}
                type="select"
                label="Webhook"
                placeholder="Select Webhook"
                options={webhookSelectOptions}
                disableClearing
                disableSingleOptionUncheck
                required
              />
              {webhookExternalIp && <span>Webhook External IP: {webhookExternalIp}</span>}
            </div>

            {!isSalesforce && (
              <>
                <Hint type="info" className={styles.proxyHint}>
                  <p className={styles.proxyText}>
                    Select from list or add a new configuration in the following format:
                    <br />
                    <span>(http/https)://USER:PASSWORD@IP_ADDRESS:PORT</span>
                  </p>
                </Hint>

                <FormField
                  name={`endpoints[${endpointOrdinal}].proxyValue`}
                  type="autocomplete"
                  selectedOptionKey={proxyId || undefined}
                  label={`Proxy ${proxyId ? "(SELECTED)" : ""}`}
                  placeholder="Your webhook proxy"
                  options={proxyOptions}
                  asyncOptions={{ loading: proxyLoading }}
                  onInputValueChange={handleProxyInputChange}
                  onValueSelect={handleProxyValueSelect}
                  sortOptions
                  moveSelectedOptionToTop={false}
                />
              </>
            )}
            {!isInReconfiguration && <AttachFile />}
          </div>
        </div>
        <div className={styles.rightPanelWrap}>
          {apiType && (
            <>
              <div
                className={cn(styles.rightPanelContentPart, {
                  [styles.rightPanelContentPartJob]: isJobEditing,
                })}
              >
                <EndpointAPIDispatcher
                  apiType={apiType}
                  endpointOrdinal={endpointOrdinal}
                  handleDisableSubmit={handleDisableSubmit}
                ></EndpointAPIDispatcher>
                <Button
                  className={styles.submitButton}
                  type="submit"
                  disabled={isBtnDisabled}
                  loading={isSubmitting}
                >
                  {submitButtonText}
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  },
);

export { Endpoint };

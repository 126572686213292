import { object, SchemaOf, string, mixed } from "yup";
import { FC } from "react";
import { FormField } from "@epcnetwork/core-ui-kit";

import { testJSONValidity } from "utils";
import { requiredFieldText } from "constants/form.constants";

export const blueshiftSchema: SchemaOf<BlueshiftType> = object({
  apiKey: string().required(requiredFieldText),
  custom_attributes: mixed()
    .required(requiredFieldText)
    .test("json", "JSON is invalid", (value) => testJSONValidity(value)),
});

export type BlueshiftType = {
  apiKey: string;
  custom_attributes: string;
};

type BlueshiftProps = {
  endpointOrdinal: number;
};

const Blueshift: FC<BlueshiftProps> = ({ endpointOrdinal }) => {
  return (
    <>
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.apiKey`}
        label="Api Key"
        placeholder="Api Key"
      />
      <FormField
        type="code"
        name={`endpoints[${endpointOrdinal}].connector.properties.custom_attributes`}
        label="Customer Data"
      />
    </>
  );
};

export { Blueshift };

import { object, string, SchemaOf, mixed } from "yup";
import { FC, useState } from "react";
import { useFormikContext } from "formik";
import {
  Button,
  FormField,
  Select,
  notification,
  useCall,
  isEmpty,
  useDidMount,
} from "@epcnetwork/core-ui-kit";

import { testJSONValidity } from "utils";
import { SelectOption } from "models";
import { requiredFieldText } from "constants/form.constants";
import { getSoapLists } from "api";
import { InitialValues } from "../jobs-form.types";

import styles from "../job-form.module.css";

export const salesforceSchema: SchemaOf<SalesforceType> = object({
  clientId: string().required(requiredFieldText),
  clientSecret: string().required(requiredFieldText),
  origin: string().required(requiredFieldText),
  authOrigin: string().required(requiredFieldText),
  soapOrigin: string().required(requiredFieldText),
  listId: string().required(requiredFieldText),
  custom_attributes: mixed()
    .required(requiredFieldText)
    .test("json", "JSON is invalid", (value) => testJSONValidity(value)),
});

type DataExtensionType = SelectOption<string>;

export type SalesforceType = {
  clientId: string;
  clientSecret: string;
  origin: string;
  authOrigin: string;
  soapOrigin: string;
  listId: string;
  custom_attributes: string;
};

type SalesforceProps = {
  endpointOrdinal: number;
};

const Salesforce: FC<SalesforceProps> = ({ endpointOrdinal }) => {
  const { values, errors, setFieldValue, submitCount } = useFormikContext<InitialValues>();

  const [dataExtensionOptions, setDataExtensionOptions] = useState<DataExtensionType[]>([]);

  const salesforceValues = values.endpoints[endpointOrdinal]?.connector.properties as
    | SalesforceType
    | undefined;

  const { clientId, clientSecret, origin, authOrigin, soapOrigin, listId } = salesforceValues || {};

  const { submit, submitting, onCallSuccess, onCallError } = useCall(getSoapLists);

  onCallSuccess((payload) => {
    if (!payload || !payload.length)
      return notification.warning("No Data", "Please check credentials and try again");

    const optionsToSave: DataExtensionType[] = payload.map(({ listId, name }) => ({
      value: listId ?? "",
      label: name,
    }));

    notification.success("Success fetch", "Data was received, check select options");
    setDataExtensionOptions(optionsToSave);
  });

  onCallError(() => {
    notification.error("FetchError", "Some error happened, please try again");
  });

  const isListDisabled =
    !clientId || !clientSecret || !origin || !authOrigin || !soapOrigin || submitting;

  useDidMount(() => {
    if (!isListDisabled) {
      handleGetDataExtensionsClick();
    }
  });

  const handleGetDataExtensionsClick = () => {
    setDataExtensionOptions([]);

    submit({
      data: {
        apiType: "salesforce",
        soapProps: {
          clientId: clientId?.trim(),
          clientSecret: clientSecret?.trim(),
          origin: origin?.trim(),
          authOrigin: authOrigin?.trim(),
          soapOrigin: soapOrigin?.trim(),
        },
      },
    });
  };

  const handleListSelect = (option: SelectOption<string>) => {
    const { value } = option || {};
    setFieldValue(`endpoints[${endpointOrdinal}].connector.properties.listId`, value || "");
  };

  const errorsAny: any = errors;
  const error =
    (!isEmpty(errorsAny) &&
      !isEmpty(errorsAny.endpoints) &&
      errorsAny?.endpoints[endpointOrdinal]?.connector?.properties?.listId) ||
    "";
  const errorText = !!submitCount && error ? error : "";

  return (
    <>
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.clientId`}
        label="Client ID"
        placeholder="Client ID"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.clientSecret`}
        label="Client Secret"
        placeholder="Client Secret"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.origin`}
        label="Origin"
        placeholder="Origin"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.authOrigin`}
        label="Auth Origin"
        placeholder="Auth Origin"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.soapOrigin`}
        label="Soap Origin"
        placeholder="Soap Origin"
      />
      <Select
        label="Data extension"
        placeholder="Data extension"
        name={`endpoints[${endpointOrdinal}].connector.properties.listId`}
        error={errorText}
        selectedOptionsKeys={listId}
        options={dataExtensionOptions}
        onChange={handleListSelect}
        disabled={isListDisabled}
      />
      <Button
        disabled={isListDisabled}
        onClick={handleGetDataExtensionsClick}
        className={styles.btnDataExtensions}
      >
        Get data extensions
      </Button>
      <FormField
        type="code"
        name={`endpoints[${endpointOrdinal}].connector.properties.custom_attributes`}
        label="ET JSON Body:"
      />
    </>
  );
};

export { Salesforce };

import { object, SchemaOf, string, mixed } from "yup";
import { FC } from "react";
import { FormField } from "@epcnetwork/core-ui-kit";

import { testJSONValidity } from "utils";
import { requiredFieldText } from "constants/form.constants";

export const iterableSchema: SchemaOf<IterableType> = object({
  apiKey: string().required(requiredFieldText),
  listId: string().required(requiredFieldText),
  custom_attributes: mixed().test("json", "JSON is invalid", (value) =>
    typeof value !== "undefined" ? testJSONValidity(value) : true,
  ),
});

export type IterableType = {
  apiKey: string;
  listId: string;
  custom_attributes?: string;
};

type IterableProps = {
  endpointOrdinal: number;
};

const Iterable: FC<IterableProps> = ({ endpointOrdinal }) => {
  return (
    <>
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.apiKey`}
        label="Api Key"
        placeholder="Api Key"
      />
      <FormField
        type="text"
        name={`endpoints[${endpointOrdinal}].connector.properties.listId`}
        label="List ID"
        placeholder="List ID"
      />
      <FormField
        type="code"
        name={`endpoints[${endpointOrdinal}].connector.properties.custom_attributes`}
        label="Data fields (optional):"
      />
    </>
  );
};

export { Iterable };
